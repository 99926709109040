import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { getAllProjectById } from '../../services';
import { API_URL } from '../../api/api';
import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import Select from 'react-select';
import { monthsEN, monthsTR, ProjectComplete, ProjectCompleteEN } from '../../utility/general';
import { motion } from 'framer-motion';
import { ChevronsDown, ChevronUp } from 'react-feather';
import { mobilVariant, projectVariant2 } from '../../components/animation';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import DOMPurify from 'dompurify';
import { fetchPageByHeaders } from '../../store/slices/pageByHeaderSlice';
import { fetchServices } from '../../store/slices/serviceSlice';
import { fetchCountrys } from '../../store/slices/countrySlice';
import { fetchProjects } from '../../store/slices/projectSlice';

const ProjectPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [lang, setLang] = useState(localStorage.getItem("lang") || "EN");
    const data = useSelector((state) => state.pageByHeader.page);
    const countries = useSelector((state) => state.country.countries);
    const proj = useSelector((state) => state.project.projects);
    const serv = useSelector((state) => state.service.services);
    const [projects, setProjects] = useState([]);
    const [pTypes, setPTypes] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [displayText, setDisplayText] = useState('');
    const [modal, setModal] = useState(false);
    const [spType, setSpType] = useState(null);
    const [load, setLoad] = useState(false);
    const [sCountry, setSCountry] = useState(null);
    const [sComplet, setSComplet] = useState(undefined);
    const [selectProject, setSelectProject] = useState([]);
    const [isSpin, setIsSpin] = useState(false)
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        dispatch(fetchPageByHeaders());
        dispatch(fetchServices());
        dispatch(fetchCountrys());
        dispatch(fetchProjects());
    }, [dispatch]);

    const cleanHtml = (html) => {
        return DOMPurify.sanitize(html);
    };

    useEffect(() => {
        if (data) {
            const parsedText = lang === "TR" ? data.imageText : data.imageTextEN;
            setDisplayText(parsedText);
        }
        if (data !== undefined && data !== null) {
            setTimeout(() => {
                setLoad(true);
            }, 300);
        }
    }, [data, lang]);

    const scrollToSection = () => {
        scroll.scrollMore(window.innerHeight * 1, {
            duration: 500,
            smooth: "easeInOutQuart",
        });
    };

    useEffect(() => {
        if (serv.length > 0) {
            const pTypesOptions = serv.map(service => {
                try {
                    const parsedTitle = lang === "TR" ? service.title : service.titleEN;
                    return {
                        label: parsedTitle,
                        options: service.projectTypes.map(type => {
                            try {
                                const parsedTypeTitle = lang === "TR" ? type.title : type.titleEN;
                                return {
                                    label: parsedTypeTitle,
                                    value: type.projectTypeID
                                };
                            } catch (error) {
                                console.error('Error parsing type title:', error);
                                return {
                                    label: 'Default Type Title',
                                    value: type.projectTypeID
                                };
                            }
                        })
                    };
                } catch (error) {
                    console.error('Error parsing service title:', error);
                    return {
                        label: 'Default Title',
                        options: service.projectTypes.map(type => ({
                            label: 'Default Type Title',
                            value: type.projectTypeID
                        }))
                    };
                }
            });
            setPTypes(pTypesOptions);
        }

        if (countries.length > 0) {
            const countryOptions = countries.map(country => {
                try {
                    const parsedName = lang === "TR" ? country.name : country.nameEN;
                    return {
                        label: parsedName,
                        value: country.countryID
                    };
                } catch (error) {
                    console.error('Error parsing country name:', error);
                    return {
                        label: 'Default Country Name',
                        value: country.countryID
                    };
                }
            });
            setCountryOptions(countryOptions);
        }
    }, [serv, countries, lang]);

    const filterProjects = (projects, spType, sCountry, sComplet) => {
        if (projects) {
            return projects.filter(project => {
                const isMatchingType = spType ? project.projectTypeID === spType : true;
                const isMatchingCountry = sCountry ? project.countryID === sCountry : true;
                const isMatchingCompletion = sComplet !== undefined ? project.IsFinished === sComplet : true;

                return isMatchingType && isMatchingCountry && isMatchingCompletion;
            });
        }
    };

    const getFilterData = () => {
        var newList = []

        if (sComplet === undefined) {
            if (spType !== null) {
                proj.filter(proje => proje.projectTypeID === spType)
            } else if (sCountry !== null) {
                proj.filter(proje => proje.countryID === sCountry)
            }
            setProjects(proj)
        }

        projects.forEach(element => {
            if (element.isFinished === sComplet) {
                newList.push(element)
            }
        });
        setSComplet(undefined)
        setProjects(newList)
    }

    useEffect(() => {
        const filteredProjects = filterProjects(proj, spType, sCountry, sComplet);
        setProjects(filteredProjects);
        setTimeout(() => {
            setLoad(true)
            setIsSpin(true)
        }, 300);
    }, [spType, sCountry, sComplet, proj]);

    useEffect(() => {
        const handleScroll = () => {
            setShowScrollToTop(window.scrollY > 700);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (id) {
            const serviceProjects = proj.filter(project => Number(project.projectType.serviceID) === Number(id));
            setProjects(serviceProjects);
            setTimeout(() => {
                setLoad(true)
                setIsSpin(true)
            }, 300);
        } else {
            setProjects(proj);
            setTimeout(() => {
                setLoad(true)
                setIsSpin(true)
            }, 300);
        }
    }, [id, proj]);

    const handleProjectClick = async (projectID) => {
        const projData = await getAllProjectById(projectID);
        const result = Array.isArray(projData.result) ? projData.result : [projData.result];
        setSelectProject(result[0]);
        if (result.length > 0) {
            setModal(!modal);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <motion.div {...mobilVariant}>
            {data && data.seo && (
                <Helmet>
                    <title>{data.seo.seoTitle || 'Default Title'}</title>
                    <meta name="description" content={data.seo.seoDescription || 'Default description'} />
                    <meta name="keywords" content={data.seo.seoKeywords || 'Default keywords'} />
                    <meta name="author" content={data.seo.seoAuthor || 'Default author'} />
                </Helmet>
            )}
            <div className='projects'>
                <Header />

                <div className='top'>
                    {data?.fileFullPath && (
                        <img
                            src={`${API_URL}${data.fileFullPath}`}
                            alt="Descriptive text about the image"
                            onError={(e) => e.target.src = 'fallback-image-url'}
                        />
                    )}
                    {/* <div className="triangle"></div> */}
                    <div className='text'>
                        <div dangerouslySetInnerHTML={{ __html: cleanHtml(displayText) }}></div>
                    </div>
                    <div className='bottombtn shadow' onClick={scrollToSection}>
                        <ChevronsDown size={35} />
                    </div>
                </div>

                <div className='project-grid'>
                    <Row>
                        <Col md={4} className='mb-3'>
                            <div className="select-wrapper">
                                <Label className='ml-2'>{lang === "TR" ? "Proje Tipi" : "Project Type"}</Label>
                                <Select
                                    options={pTypes}
                                    placeholder={lang === "TR" ? "Proje Tipi Seçiniz" : "Select Project Type"}
                                    onChange={e => setSpType(e.value)}
                                />
                            </div>
                        </Col>
                        <Col md={4} className='mb-3'>
                            <div className="select-wrapper">
                                <Label className='ml-2'>{lang === "TR" ? "Ülke" : "Country"}</Label>
                                <Select
                                    options={countryOptions}
                                    placeholder={lang === "TR" ? "Ülke Seçiniz" : "Select Country"}
                                    onChange={e => setSCountry(e.value)}
                                />
                            </div>
                        </Col>
                        <Col md={4} className='mb-3'>
                            <div className="select-wrapper">
                                <Label className='ml-2'>{lang === "TR" ? "Proje Durumu" : "Project Status"}</Label>
                                <Select
                                    options={lang === "TR" ? ProjectComplete : ProjectCompleteEN}
                                    placeholder={lang === "TR" ? "Proje Durumu Seçiniz" : "Select Status Project"}
                                // onChange={e => { setSComplet(e.value === 1 ? false : true); getFilterData() }}
                                />
                            </div>
                        </Col>
                    </Row>

                    {load ? projects.length > 0 && isSpin ? (
                        <motion.div {...projectVariant2}>
                            <Row className='mt-5'>
                                {[...projects]
                                    .sort((a, b) => a.sort - b.sort) // Artan sırada sıralamak için
                                    .map((item, index) => {
                                        const title = lang === "TR" ? item.title : item.titleEN;
                                        const country = lang === "TR" ? item.country.name : item.country.nameEN;
                                        return (
                                            <Col md={3} key={index} className='mb-4'>
                                                <div className='item border' onClick={() => handleProjectClick(item.projectID)}>
                                                    <div>
                                                        <img src={`${API_URL}${item.fileFullPath}`} alt={title} />
                                                    </div>
                                                    <div className='p-3 mt-2'>
                                                        <div className='text-center'>
                                                            <h4>{title}</h4>
                                                        </div>
                                                        <div className='mt-2 text-center'>
                                                            <span>{country}</span>
                                                        </div>
                                                        <div className='mt-4 d-flex justify-content-center'>
                                                            <Button className='px-4' color='dark'>
                                                                {lang === "TR" ? "Daha Fazla İncele" : "View More"}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                        </motion.div>
                    ) : (
                        <div className="text-center mt-5">
                            {isSpin ? <Spinner color='info'> </Spinner> : <span>{lang === "TR" ? "Üzgünüz, bu kriterlere uyan bir projemiz yok." : "Sorry, we do not have a project that matches those criteria."}</span>}
                        </div>
                    ) : <div className="text-center mt-5">
                        {!isSpin ? <Spinner color='info'> </Spinner> : <span>{lang === "TR" ? "Üzgünüz, bu kriterlere uyan bir projemiz yok." : "Sorry, we do not have a project that matches those criteria."}</span>}
                    </div>}
                </div>
                <Footer />

                {selectProject.length !== 0 && (
                    <Modal isOpen={modal} toggle={() => setModal(!modal)} className='custom-modal modal-dialog-centered'>
                        <ModalHeader className='border-0' toggle={() => setModal(!modal)} tag='h4'></ModalHeader>
                        <ModalBody className='text-center'>
                            <div style={{ marginTop: -30 }}>
                                <img
                                    src={`${API_URL}${selectProject.fileFullPath}`}
                                    alt='Project Image'
                                    className='w-100'
                                    style={{ height: 300 }}
                                />
                            </div>
                            <div className='mt-3'>
                                <h4>{lang === "TR" ? selectProject.title : selectProject.titleEN}</h4>
                            </div>
                            <div className='mt-1'>
                                <b><small>{lang === "TR" ? selectProject.city : selectProject.cityEN} - {lang === "TR" ? selectProject.country.name : selectProject.country.nameEN}</small></b>
                            </div>
                            <div className='mt-3 text-secondary'>
                                <small>{lang === "TR" ? selectProject.description : selectProject.descriptionEN}</small>
                            </div>
                            <div className='mt-3'>
                                <b>{lang === "TR" ? "Proje Bitiş" : "Finished Project"}</b>:
                                <span className='text-secondary ml-2'>
                                    {selectProject.finish ?
                                        `${lang === "TR" ? monthsTR[new Date(selectProject.finish).getMonth()] : monthsEN[new Date(selectProject.finish).getMonth()]} ${new Date(selectProject.finish).getFullYear()}`
                                        : 'No Date'}
                                </span>
                            </div>
                            <div className='mt-3'>
                                <b>{lang === "TR" ? "Müşteri" : "Client"}</b>:
                                <span className='text-secondary ml-2'>{lang === "TR" ? selectProject.client : selectProject.clientEN}</span>
                            </div>
                        </ModalBody>
                    </Modal>
                )}

                {showScrollToTop && (
                    <div className="scroll-to-top" onClick={scrollToTop}>
                        <ChevronUp />
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default ProjectPage;
