import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllHeaders } from '../../services';

export const fetchShowCases = createAsyncThunk(
    'showCase/fetchShowCases',
    async () => {
        const showC = localStorage.getItem("showCase")
        if (showC) {
            return JSON.parse(showC)
        }
        const response = await getAllHeaders();
        var sc = []
        if (response.result !== null) {
            response.result.forEach(element => {
                if (element.isShowCase) {
                    sc.push(element)
                }
            });
        }
        localStorage.setItem("showCase", JSON.stringify(sc))
        return sc;
    }
);

const showCaseSlice = createSlice({
    name: 'header',
    initialState: {
        sc: [],
        status: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchShowCases.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchShowCases.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.sc = action.payload;
            })
            .addCase(fetchShowCases.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default showCaseSlice.reducer;
