import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllProjects } from '../../services';

export const fetchProjects = createAsyncThunk(
    'project/fetchProjects',
    async () => {
        const project = localStorage.getItem("project")
        if (project) {
            return JSON.parse(project)
        }
        const response = await getAllProjects();
        localStorage.setItem("project", JSON.stringify(response.result))
        return response.result;
    }
);

const projectSlice = createSlice({
    name: 'project',
    initialState: {
        projects: [],
        status: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.projects = action.payload;
            })
            .addCase(fetchProjects.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default projectSlice.reducer;
