import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-flip';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';
import slider1 from '../../images/what-we-do.png';
import slider2 from '../../images/slide-2.jpg';
import slider3 from '../../images/slide-3.jpg';
import slider4 from '../../images/slide-4.jpg';
import img1 from '../../images/img1.jpeg';
import img2 from '../../images/img2.jpeg';
import img3 from '../../images/img3.jpeg';
import img4 from '../../images/img4.jpeg';
import img5 from '../../images/img5.jpeg';
import img6 from '../../images/img6.jpeg';
import img7 from '../../images/img7.jpeg';
import img8 from '../../images/img8.jpeg';
import img9 from '../../images/img9.jpeg';
import img10 from '../../images/img10.jpeg';
import he from 'he';
import { Col, Row } from 'reactstrap';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { API_URL } from '../../api/api';
import { motion } from 'framer-motion';
import { mobilVariant } from '../../components/animation';
import bg from '../../images/mapa-logo-light-overlay.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHomeSliders } from '../../store/slices/homeSliderSlice';
import { fetchServices } from '../../store/slices/serviceSlice';
import { HomeAboutList } from '../../utility/general';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const services = useSelector((state) => state.service.services);
    const hSlider = useSelector((state) => state.homeSlider.sliders);
    const [load, setLoad] = useState(false);
    const [lang, setLang] = useState(localStorage.getItem("lang") || "EN");

    const cleanHtml = (html) => {
        if (html) {
            let cleanedHtml = he.decode(html);
            cleanedHtml = cleanedHtml.replace(/<\/?pre[^>]*>/g, '');
            return cleanedHtml;
        }
        return '';
    };

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchHomeSliders());
            await dispatch(fetchServices());
        }
        fetchData();
        if (hSlider !== null && hSlider !== undefined) {
            setTimeout(() => {
                setLoad(true);
            }, 500);
        }
    }, [dispatch]);

    return (
        <motion.div {...mobilVariant}>
            <div style={{ width: '100%' }}>
                <Header />

                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    autoplay={{ delay: 2000, disableOnInteraction: false }}
                    loop={true}
                    pagination={{ clickable: true }}
                    navigation={true}
                    speed={1000}
                    effect="fade"
                    fadeEffect={{ crossFade: true }}
                    modules={[Autoplay, Pagination, Navigation, EffectFade]}
                    className='main-swiper'
                >
                    {hSlider.length > 0 ? hSlider.map((item, index) => {
                        const pageData = lang === "TR" ? item.shortDescription : item.shortDescriptionEN;
                        const imageUrl = item.fileFullPath ? `${API_URL}${item.fileFullPath}` : '';
                        const description = pageData;

                        return (
                            <SwiperSlide key={index}>
                                {imageUrl && <img src={imageUrl} alt="Slide" className='item' />}
                                <div className='text'><h1><div dangerouslySetInnerHTML={{ __html: cleanHtml(description) }}></div></h1></div>
                            </SwiperSlide>
                        );
                    }) : null}
                </Swiper>

                <div className='faaliyet'>
                    <div className='text-center'>
                        <h2>{lang === "TR" ? "Faaliyet Alanlarımız" : "Our Fields of Activity"}</h2>
                    </div>
                    <Row className='d-flex justify-content-center mt-3'>
                        {services.map((item, index) => {
                            const title = lang === "TR" ? item.title : item.titleEN

                            return (
                                <Col key={index} md={5} className='mb-4'>
                                    <div className='shadow item'>
                                        <Swiper
                                            spaceBetween={0}
                                            slidesPerView={1}
                                            autoplay={{ delay: 2000, disableOnInteraction: false }}
                                            loop={true}
                                            speed={1000}
                                            effect="fade"
                                            fadeEffect={{ crossFade: true }}
                                            modules={[Autoplay, EffectFade]}
                                            className='faaliyetslider'
                                        >
                                            <SwiperSlide><img src={index === 0 ? img1 : img6} alt="Slide 1" /></SwiperSlide>
                                            <SwiperSlide><img src={index === 0 ? img2 : img7} alt="Slide 2" /></SwiperSlide>
                                            <SwiperSlide><img src={index === 0 ? img3 : img8} alt="Slide 3" /></SwiperSlide>
                                            <SwiperSlide><img src={index === 0 ? img4 : img9} alt="Slide 4" /></SwiperSlide>
                                            <SwiperSlide><img src={index === 0 ? img5 : img10} alt="Slide 4" /></SwiperSlide>
                                        </Swiper>
                                        <div className='p-3'>
                                            <div className="p-3 text-center">
                                                <h5>{title}</h5>
                                            </div>
                                            <div>
                                                <div
                                                    className='btn'
                                                    onClick={() => {
                                                        localStorage.setItem("headerID", 4);
                                                        navigate(lang === "TR" ? "/icerik/projeler" : '/content/projects');
                                                    }}
                                                >
                                                    {lang === "TR" ? "Daha Fazla Bilgi Edinin" : "Learn More"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </div>

                <div className='main-info overflow-hidden'>
                    <img src={slider1} alt='' />
                    <div className='show'>
                        <div className='position-relative'>
                            <img src={bg} alt='' className='position-absolute' />
                            <ul>
                                {HomeAboutList.map((item, index) => (
                                    <li key={index}>
                                        {lang === "TR" ? item.TR : item.EN}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </motion.div>
    );
}

export default HomePage;
