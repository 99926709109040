import React from 'react'
import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from '../pages/homepage';
import ContentPage from '../pages/contentpage';
import JobPage from '../pages/jobPage';
import ProjectPage from '../pages/projectPage';
import ContactPage from '../pages/contact';

const AnimatedRoutes = () => {
    const location = useLocation()

    return (
        <AnimatePresence>
            <Routes key={location.pathname} location={location}>
                <Route path='/' element={<HomePage />} />
                <Route path='/content/:id' element={<ContentPage />} />
                <Route path='/icerik/:id' element={<ContentPage />} />
                <Route path='/content/job' element={<JobPage />} />
                <Route path='/icerik/is-ilanlari' element={<JobPage />} />
                <Route path='/content/' element={<HomePage />} />
                <Route path='/icerik/' element={<HomePage />} />
                <Route path='/content/projects' element={<ProjectPage />} />
                <Route path='/icerik/projeler' element={<ProjectPage />} />
                <Route path='/content/projects/:id' element={<ProjectPage />} />
                <Route path='/icerik/projeler/:id' element={<ProjectPage />} />
                <Route path='/content/contact' element={<ContactPage />} />
                <Route path='/contact' element={<ContactPage />} />
                <Route path='/icerik/iletisim' element={<ContactPage />} />
                <Route path='/iletisim' element={<ContactPage />} />
                <Route path='/content/:page/:id' element={<ContentPage />} />
                <Route path='/icerik/:page/:id' element={<ContentPage />} />
                <Route path='/tr/:page/:id' element={<ContentPage />} />
                <Route path='/:page/:id' element={<ContentPage />} />
                <Route path='/:page' element={<ContentPage />} />
                <Route path='/tr/:page' element={<ContentPage />} />
                <Route path='/:id' element={<ContentPage />} />
                <Route path='/tr/:id' element={<ContentPage />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes