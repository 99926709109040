import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllCountrys } from '../../services';

export const fetchCountrys = createAsyncThunk(
    'country/fetchCountrys',
    async () => {
        const country = localStorage.getItem("country")
        if (country) {
            return JSON.parse(country)
        }
        const response = await getAllCountrys();
        localStorage.setItem("country", JSON.stringify(response.result))
        return response.result;
    }
);

const countrySlice = createSlice({
    name: 'country',
    initialState: {
        countries: [],
        status: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountrys.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCountrys.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.countries = action.payload;
            })
            .addCase(fetchCountrys.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default countrySlice.reducer;
