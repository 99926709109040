import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllServices } from '../../services';

export const fetchServices = createAsyncThunk(
    'service/fetchServices',
    async () => {
        const service = localStorage.getItem("service")
        if (service) {
            return JSON.parse(service)
        }
        const response = await getAllServices();
        localStorage.setItem("service", JSON.stringify(response.result))
        return response.result;
    }
);

const serviceSlice = createSlice({
    name: 'service',
    initialState: {
        services: [],
        status: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchServices.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchServices.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.services = action.payload;
            })
            .addCase(fetchServices.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default serviceSlice.reducer;
