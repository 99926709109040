import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllImageGroupById } from '../../services';

export const fetchHomeSliders = createAsyncThunk(
    'homeSlider/fetchHomeSliders',
    async () => {
        const homeSlider = localStorage.getItem("homeSlider")
        if (homeSlider) { 
            return JSON.parse(homeSlider)
        }
        const response = await getAllImageGroupById(1)
        localStorage.setItem("homeSlider", JSON.stringify(response.result.images))
        return response.result.images;
    }
);

const homeSliderSlice = createSlice({
    name: 'homeSlider',
    initialState: {
        sliders: [],
        status: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHomeSliders.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchHomeSliders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.sliders = action.payload;
            })
            .addCase(fetchHomeSliders.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default homeSliderSlice.reducer;
