import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllJobs } from '../../services';

export const fetchJobs = createAsyncThunk(
    'jobs/fetchJobs',
    async () => {
        const jobs = localStorage.getItem("jobs")
        if (jobs) {
            return JSON.parse(jobs)
        }
        const response = await getAllJobs();
        localStorage.setItem("jobs", JSON.stringify(response.result))
        return response.result;
    }
);

const jobsSlice = createSlice({
    name: 'jobs',
    initialState: {
        jobs: [],
        status: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchJobs.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchJobs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.jobs = action.payload;
            })
            .addCase(fetchJobs.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default jobsSlice.reducer;
