import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllPageByUrl } from '../../services';
import { urlById } from '../../utility/general';

export const fetchPageByHeaders = createAsyncThunk(
    'pageByHeaders/fetchPageByHeaders',
    async () => {
        var lang = localStorage.getItem("lang")
        const url = urlById(window.location.pathname.replace(lang === "TR" ? "/icerik/" : "/content/", ""))
        var page = localStorage.getItem(`page-${url}`)
        if (page) {
            return JSON.parse(page)
        }
        const response = await getAllPageByUrl(url);
        localStorage.setItem(`page-${url}`, JSON.stringify(response.result))
        return response.result;
    }
);

const pageByHeaderSlice = createSlice({
    name: 'pageByHeader',
    initialState: {
        page: [],
        status: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPageByHeaders.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPageByHeaders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.page = action.payload;
            })
            .addCase(fetchPageByHeaders.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default pageByHeaderSlice.reducer;
