import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllHeaders } from '../../services';

export const fetchFooterMenus = createAsyncThunk(
    'footerMenu/fetchFooterMenus',
    async () => {
        const footerM = localStorage.getItem("footerMenu")
        if (footerM) {
            return JSON.parse(footerM)
        }
        const response = await getAllHeaders();
        var fm = []
        if (response.result !== null) {
            response.result.forEach(element => {
                if (element.isFooter) {
                    fm.push(element)
                }
            });
        }
        localStorage.setItem("footerMenu", JSON.stringify(fm))
        return fm;
    }
);

const footerMenuSlice = createSlice({
    name: 'header',
    initialState: {
        fm: [],
        status: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFooterMenus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFooterMenus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fm = action.payload;
            })
            .addCase(fetchFooterMenus.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default footerMenuSlice.reducer;
