import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllSocialMedias } from '../../services';

export const fetchSocialMedias = createAsyncThunk(
    'socialMedia/fetchSocialMedias',
    async () => {
        const social = localStorage.getItem("social")
        if (social) {
            return JSON.parse(social)
        }
        const response = await getAllSocialMedias();
        localStorage.setItem("social", JSON.stringify(response.result))
        return response.result;
    }
);

const socialMediaSlice = createSlice({
    name: 'socialMedia',
    initialState: {
        socialMedias: [],
        status: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSocialMedias.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSocialMedias.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.socialMedias = action.payload;
            })
            .addCase(fetchSocialMedias.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default socialMediaSlice.reducer;
