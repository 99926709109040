import { configureStore } from '@reduxjs/toolkit';
import headerReducer from './slices/headerSlice';
import languageReducer from './slices/languageSlice';
import socialMediaReducer from './slices/socialMediaSlice';
import settingReducer from './slices/settingSlice';
import showCaseReducer from './slices/showCaseSlice';
import footerMenuReducer from './slices/footerMenuSlice';
import serviceReducer from './slices/serviceSlice';
import homeSliderReducer from './slices/homeSliderSlice';
import pageByHeaderReducer from './slices/pageByHeaderSlice';
import formByIdReducer from './slices/formByIdSlice';
import countryReducer from './slices/countrySlice';
import projectReducer from './slices/projectSlice';
import jobReducer from './slices/jobSlice';
import jobsReducer from './slices/jobsSlice';
import jobSlice from './slices/jobSlice';

export const store = configureStore({
    reducer: {
        header: headerReducer,
        language: languageReducer,
        socialMedia: socialMediaReducer,
        setting: settingReducer,
        showCase: showCaseReducer,
        footerMenu: footerMenuReducer,
        service: serviceReducer,
        homeSlider: homeSliderReducer,
        pageByHeader: pageByHeaderReducer,
        formById: formByIdReducer,
        country: countryReducer,
        project: projectReducer,
        job: jobReducer,
        jobs: jobsReducer,
    },
});

export default store;
