import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllLanguages } from '../../services';

export const fetchLanguages = createAsyncThunk(
    'language/fetchLanguages',
    async () => {
        const lang = localStorage.getItem("languages")
        if (lang) {
            return JSON.parse(lang)
        }
        const response = await getAllLanguages();
        localStorage.setItem("languages", JSON.stringify(response.result))
        return response.result;
    }
);

const languageSlice = createSlice({
    name: 'language',
    initialState: {
        languages: [],
        status: 'idle',
        initialized: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLanguages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLanguages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.languages = action.payload;
                state.initialized = true;
            })
            .addCase(fetchLanguages.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default languageSlice.reducer;
