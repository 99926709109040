// src/slices/settingSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllSettingById } from '../../services';

export const fetchSettingById = createAsyncThunk(
    'setting/fetchSettingById',
    async () => {
        const setting = localStorage.getItem("setting")
        if(setting){
            return JSON.parse(setting)
        }
        const response = await getAllSettingById(1)
        localStorage.setItem("setting", JSON.stringify(response.result))
        return response.result
    }
);

const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        settings: null,
        status: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSettingById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSettingById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.settings = action.payload;
            })
            .addCase(fetchSettingById.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default settingSlice.reducer;
