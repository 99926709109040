import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllHeaders } from '../../services';

export const fetchHeaders = createAsyncThunk(
    'header/fetchHeaders',
    async () => {
        const header = localStorage.getItem("header")
        if (header) {
            return JSON.parse(header)
        }
        const response = await getAllHeaders();
        localStorage.setItem("header", JSON.stringify(response.result))
        return response.result;
    }
);

const headerSlice = createSlice({
    name: 'header',
    initialState: {
        menus: [],
        status: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHeaders.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchHeaders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.menus = action.payload;
            })
            .addCase(fetchHeaders.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default headerSlice.reducer;
